import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { TeamMember } from "../TeamMember";
import { useFormContext, useFieldArray } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { CommunicationType, Status } from "../../interfaces/Agent";
import { TeamMemberHeader } from "../TeamMemberHeader";
import { useEffect, useState } from "react";

export interface TeamAccordianSectionProps {
  disabled: boolean
  expanded: boolean[];
  onChange: (
    index: number,
    expanded: boolean,
    event?: React.SyntheticEvent
  ) => void;
}

export function TeamAccordianSection(props: TeamAccordianSectionProps) {
  const { disabled, expanded, onChange } = props;
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "agents",
  });

  const handleRemoveAgent = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };
  const handleAddAgent = () => {
    append({
      status: Status.AVAILABLE,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      availability: {},
      prefered_communication: CommunicationType.TEXT,
    });
  };

  useEffect(() => {
    if (errors.agents)
      setExpandedIndex(parseInt(Object.keys(errors.agents)[0]));
  }, [errors]);

  return (
    <Stack
      sx={{ width: "100%" }}
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Stack
        sx={{ width: "100%" }}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        {fields.map((agent, index: number) => {
          const divid = `agent-wrapper-${index}`;
          const shouldOpen = index === expandedIndex;
          return (
            <Box id={divid} sx={{ width: "100%" }}>
              <Accordion
                key={agent.id}
                slotProps={{ transition: { unmountOnExit: true } }}
                disableGutters
                elevation={0}
                square
                sx={{
                  width: "100%",
                  backgroundColor: "#F8FAFE",
                  "&:hover": !shouldOpen
                    ? {
                      backgroundColor: "#DCE0F0",
                    }
                    : undefined,
                }}
                disabled={disabled}
                expanded={shouldOpen}
                onChange={(e, isExpanded) => {
                  setExpandedIndex(isExpanded ? index : -1);
                  if (isExpanded)
                    setTimeout(() => {
                      document.getElementById(divid)?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }, 500);
                }}
              >
                <AccordionSummary>
                  <TeamMemberHeader index={index} />
                </AccordionSummary>
                <AccordionDetails>
                  <TeamMember
                    index={index}
                    showRemove={fields.length > 1}
                    expanded={expanded.length > index ? expanded[index] : false}
                    onAgentRemove={() => handleRemoveAgent(index)}
                    onExpandChange={(e, expand) => {
                      onChange(index, e, expand);
                      if (expand)
                        setTimeout(() => {
                          document.getElementById(divid)?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }, 500);
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </Stack>
      <Button
        variant="outlined"
        type="button"
        sx={{ width: { xs: "100%", md: 200 } }}
        disabled={disabled}
        onClick={() => {
          handleAddAgent();
        }}
      >
        Add more staff
      </Button>
    </Stack>
  );
}
