import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiContext from "../context/ApiContext";

const properties = {
  display: 'grid',
  placeItems: 'center',
  height: '100vh',
};
export default function StorePortalView() {
  const navigate = useNavigate();
  const {
    actions: { createStorePortalSession },
  } = useContext(ApiContext);

  const loadStoreUrl = async () => {
    try {
      const url = await createStorePortalSession(
        `${window.location.origin}`,
      );
      window.open(url, '_self');
    } catch (e) {
      navigate("/")
    }
  };

  useEffect(() => {
    loadStoreUrl();
  }, []);


  return (
    <Box sx={properties}>
      <CircularProgress size={50} />
    </Box>
  );
}
