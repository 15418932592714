import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { default as AuthContext } from "../context/AuthContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import {
  showFriendlyErrorFromHTMLCode,
  useMediaMobile,
  useReferer,
} from "../utils/utils";
import RegisterEmailSection from "../components/section/RegisterEmailSection";
import ApiContext from "../context/ApiContext";
import { AuthUser } from "../interfaces/AuthUser";
import PlainContainer from "../components/container/PlainContainer";

var owasp = require("owasp-password-strength-test");
interface IFormInput {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email field is required.")
      .max(50)
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
    password: yup
      .string()
      .required("Password field is required.")
      .max(50)
      .test((value, ctx) => {
        var result = owasp.test(value);
        if (result.errors.length > 0) {
          return ctx.createError({
            message: result.errors[0],
          });
        }
        return true;
      }),
  })
  .required();
interface RegisterViewProps {
  showStore: boolean;
}

export default function RegisterView(props: RegisterViewProps) {
  const navigate = useNavigate();
  const isMobile = useMediaMobile();
  const referer = useReferer();
  const {
    data: { email, has_password },
    actions: { getUser },
  } = useContext(ApiContext);
  const {
    actions: { isLoggedIn, createGuestAccount, updateAccount },
  } = useContext(AuthContext);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const methods = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      email: email,
      password: "",
    },
  });

  const { handleSubmit, setValue, reset } = methods;

  useEffect(() => {
    if (email) setValue("email", email);
  }, [email, setValue]);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email, password } = data;

    setError("");
    setSuccess("");

    setLoading(true);

    const accountFunction = isLoggedIn() ? updateAccount : createGuestAccount;

    accountFunction(email, password, referer)
      .then(async (token: AuthUser) => {
        await getUser(token); //Load cache to make the store redirect work correctly
        setError("");
        reset();
        navigate("/");
      })
      .catch((error) => {
        setError(showFriendlyErrorFromHTMLCode(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (has_password) return <Navigate to="/" replace={true} />;
  return (
    <PlainContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
          >
            {success && <Alert severity="success">{success} </Alert>}
            {error && <Alert severity="error">{error}</Alert>}

            <Typography variant="h3">Complete you Account</Typography>

            <RegisterEmailSection disabledEmail={!!email} />
            <Button sx={{ width: "100%" }} variant="contained" type="submit">
              Create Account {loading ? <LoadingCircle /> : <RightArrow />}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </PlainContainer>
  );
}
