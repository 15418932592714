import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaMobile } from "../../utils/utils";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../alert/ErrorAlert";

interface IFormInput {
  original_company_name: string;
}

const schema = object({
  original_company_name: string().required(),
  check_company_name: string()
    .required("Buisness Name is empty")
    .test((value, ctx) => {
      const cname = ctx.parent.original_company_name;
      if (value !== cname)
        return ctx.createError({
          message: "Must match your Business Name",
        });

      return true;
    }),
}).required();

export interface DeleteAccountDialogProps {
  open: boolean;
  onClose: (reason?: string) => void;
}

export function DeleteAccountDialog(props: DeleteAccountDialogProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaMobile();

  const {
    data: { company },
  } = useContext(AuthContext);

  const { open, onClose } = props;
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      original_company_name: company?.name || "",
      check_company_name: ""
    },
  });

  const {
    actions: { deleteAccount },
  } = useContext(AuthContext);

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    setError("");
    setSuccess("");
    setLoading(true);
    deleteAccount()
      .then((data) => {
        setError(null);
        setSuccess("You account has been deleted and you have been logged out");
        setTimeout(() => {
          navigate("/");
        }, 500);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Paper
        sx={{
          width: { xs: 320, sm: 350 },
          padding: theme.spacing(2),
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="stretch"
          >
            <ErrorAlert error={error} />
            {success && <Alert severity="success">{success} </Alert>}
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Close Account
            </Typography>
            <Box>
              <Typography
                variant="body1"
                sx={{ marginBottom: isMobile ? 0 : 1 }}
              >
                Are you sure you want to close you account and miss out on all
                the wonderful features of Nucleus? Type your buisiness name
                below to confirm
              </Typography>
              <Controller
                name="check_company_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.check_company_name !== undefined}
                    helperText={
                      errors.check_company_name
                        ? errors.check_company_name?.message
                        : "　"
                    }
                    className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                    type="text"
                    inputProps={{
                      style: {
                        padding: "10px 12px",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              type="submit"
              color="error"
            >
              Close Account
            </Button>
          </Stack>
        </form>
      </Paper>
    </Dialog>
  );
}
