import React, { useCallback, useContext, useEffect, useState } from "react";
import { default as ApiService } from "../context/ApiServices";
import { CardItem } from "../interfaces/CardItem";
import { ICityResult } from "../interfaces/ICityResult";
import { ICreateStripePriceResult } from "../interfaces/IGetUserResult";
import { IVoiceResult } from "../interfaces/IVoiceResult";
import AuthContext from "./AuthContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const ApiContext = React.createContext<{
  actions: {

    getVoices: () => Promise<IVoiceResult[]>;
    getCities: () => Promise<ICityResult[]>;

    createStoreSession: (_: string) => Promise<string>;
    createStorePortalSession: (_: string) => Promise<string>;
    createStorePriceTableSession: () => Promise<ICreateStripePriceResult>;
    createStoreUpgradeSession: (_: string) => Promise<string>;
    setLastWarning: (_: string) => void;
    createTicket: (name: string, subject: string, body: string) => Promise<any>;
  };
  data: {

    defaultCards: CardItem[];
    voices: IVoiceResult[];
    cities: ICityResult[];
    lastWarning: string | undefined;

  };
}>({
  actions: {

    getVoices: async () => [],
    getCities: async () => [],
    createStoreSession: async () => "",
    createStorePortalSession: async () => "",
    createStorePriceTableSession: async () => ({ price_id: "", secret: "" }),
    createStoreUpgradeSession: async () => "",
    setLastWarning: () => { },
    createTicket: async () => { }
  },
  data: {

    defaultCards: [],
    voices: [],
    cities: [],
    lastWarning: undefined,

  },
});

interface ApiContextProviderProps {
  children: React.ReactNode;
}

export function ApiContextProvider(
  props: ApiContextProviderProps
): React.ReactElement {
  const { children } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn },
  } = useContext(AuthContext);


  const [voices, setVoices] = useState<IVoiceResult[]>([]);
  const [cities, setCities] = useState<ICityResult[]>([]);



  const [defaultCards, setDefaultCards] = useState<CardItem[]>([]);

  const WARNING_KEY = "warning";
  const [warning, setWarning] = useState<string | undefined>(
    localStorage.getItem(WARNING_KEY) || undefined
  );
  const setLastWarning = (warning: string | undefined) => {
    setWarning(warning);
  };




  const getVoices = useCallback(() => {
    return new Promise<IVoiceResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`voices`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const voices: IVoiceResult[] = response.data;
        if (voices) {
          const sorted = voices.sort((a, b) => {
            if (a.is_premium === b.is_premium) return a.name > b.name ? 1 : -1;
            return a.is_premium && !b.is_premium ? 1 : -1;
          });
          setVoices(sorted);
          resolve(sorted);
        } else {
          resolve([]);
        }
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getCities = useCallback(() => {
    return new Promise<ICityResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`cities`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        const cities: ICityResult[] = response.data;
        if (cities) {
          const sorted = cities.sort((a, b) => {
            if (a.region === b.region) return a.city > b.city ? 1 : -1;
            return a.region > b.region ? 1 : -1;
          });

          resolve(sorted);
        } else {
          resolve([]);
        }
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getDefaultCards = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`default_context`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setDefaultCards(response.data.cards);
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createStoreSession = useCallback((return_url?: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = authToken;
        const response = await ApiService.api.post(
          `stripe/create`,
          { return_url },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createStorePortalSession = useCallback((return_url?: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = authToken;
        const response = await ApiService.api.post(
          `stripe/portal/create`,
          { return_url },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createStorePriceTableSession = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = authToken;
        const response = await ApiService.api.post(
          `stripe/pricetable/create`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createStoreUpgradeSession = useCallback((return_url?: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = authToken;
        const response = await ApiService.api.post(
          `stripe/upgrade/create`,
          { return_url },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createTicket = useCallback((name: string, subject: string, body: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = authToken;
        const response = await ApiService.api.post(
          `ticket`,
          { name, subject, body },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      const v = await getVoices();

      setVoices(v);
    }
    fetchData();
  }, [getVoices]);

  useEffect(() => {
    async function fetchData() {
      setCities(await getCities());
    }
    fetchData();
  }, [getCities]);

  useEffect(() => {
    if (isLoggedIn()) getDefaultCards();
  }, [authToken, getDefaultCards, isLoggedIn]);

  return (
    <ApiContext.Provider
      value={{
        actions: {
          getVoices,
          getCities,
          createStoreSession,
          createStorePortalSession,
          createStorePriceTableSession,
          createStoreUpgradeSession,
          setLastWarning,
          createTicket
        },
        data: {
          defaultCards,
          voices,
          cities,
          lastWarning: warning
        },
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
export default ApiContext;
