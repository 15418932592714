import { Link, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Box, Button, Stack, Alert } from "@mui/material";
import { NewtCloudLogo } from "../img/NewtCloudLogo";
import { ProfileMenu } from "../components/ProfileMenu";

export default function AppLayout() {
  const {
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  return (
    <div className="customBody customFamily">
      <nav
        className="sticky top-0 border-b transition duration-200 z-50 ease-in-out animate-header-slide-down-fade border-transparent backdrop-blur-sm px-2 py-4 lg:p-4"
        data-headlessui-state=""
        id="__FPSC_ID_1_1710833276946"
      >
        <Alert icon={false} sx={{ marginBottom: 2 }} severity="info">
          Welcome to Nucleus! This website is currently in pre-launch mode.
          Stay tuned for exciting updates and the full launch soon!
        </Alert>
        <div className="mx-auto max-w-7xl">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <a target="_parent" href="/">
              <Box sx={{ height: '75px' }}>
                <NewtCloudLogo />
              </Box>
            </a>

            {!isLoggedIn() && (
              <Link to="/signin">
                <Button>
                  <u>Sign in</u>
                </Button>
              </Link>
            )}

            {isLoggedIn() && (<ProfileMenu />)}
          </Stack>
        </div>
      </nav>
      <main>
        <div className="relative overflow-hidden isolate">
          <div className="relative px-2 lg:px-4">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}
