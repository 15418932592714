import { Crisp } from 'crisp-sdk-web';
import React, {
  ReactElement,
  useContext,
  useEffect,
} from 'react';
import ApiContext from '../../context/ApiContext';
export default function CrispChat(): ReactElement {
  const {
    data: {
      account_id,
      email
    },

  } = useContext(ApiContext);

  const loadCrisp = async () => {
    const crispId: string | undefined = process.env.REACT_APP_CRISP_WEBSITE_ID;
    if (crispId) {
      Crisp.configure(crispId);
    }
  };

  useEffect(() => {
    loadCrisp();
  }, []);


  useEffect(() => {
    Crisp.user.setEmail(email || '');
    Crisp.session.setData({
      user_id: account_id,
    });
  }, [account_id, email]);

  return <></>;
}
