import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect } from "react";
import ApiContext from "../context/ApiContext";
import { useNavigateToTop } from "../utils/utils";

const properties = {
  display: 'grid',
  placeItems: 'center',
  height: '100vh',
};
export default function StorePortalView() {
  const navigateToTop = useNavigateToTop();
  const {
    actions: { createStorePortalSession },
  } = useContext(ApiContext);

  const loadStoreUrl = async () => {
    try {
      const url = await createStorePortalSession(
        `${window.location.origin}/dashboard`,
      );
      window.open(url, '_self');
    } catch (e) {
      navigateToTop("/dashboard")
    }
  };

  useEffect(() => {
    loadStoreUrl();
  }, []);


  return (
    <Box sx={properties}>
      <CircularProgress size={50} />
    </Box>
  );
}
