import {
  Button,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import PlainContainer from "../components/container/PlainContainer";
import { useNavigateToTop } from "../utils/utils";
import { Link as RouterLink } from "react-router-dom";

const properties = {
  display: "grid",
  placeItems: "center",
  height: "100vh",
};

export default function EmailVerifyView() {
  const navigateToTop = useNavigateToTop();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const token = searchParams.get("email-token");

  const [loading, setLoading] = useState(true);
  const {
    data: { account, company },
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  const {
    actions: { verifyEmail },
  } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoggedIn() || (isLoggedIn() && company?.name)) {
      //Using this as a marker of loading
      if (account?.verified) {
        if (account.has_password) {
          navigateToTop("/dashboard");
        } else {
          navigateToTop("/complete");
        }
      } else if (email && token) {
        verifyEmail(email!, token!)
          .then(() => {
            if (account?.has_password) {
              navigateToTop("/dashboard");
            } else {
              navigateToTop("/complete");
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  }, [account?.has_password, account?.verified, company?.name, email, isLoggedIn, navigateToTop, token, verifyEmail]);

  if (loading) {
    return (
      <Box sx={properties}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <PlainContainer>
      <Grid item xs={12} spacing={3} container direction="column">
        <Grid item>
          <Typography variant="h2" align="center">
            The link has expired
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">
            If you didn’t have time to create an account, contact your admin. If
            you already have an account log in.
          </Typography>
        </Grid>
        <Grid item>
          <RouterLink to="/signin">
            <Button>Back to Login</Button>
          </RouterLink>
        </Grid>
      </Grid>
    </PlainContainer>
  );
}
