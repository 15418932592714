import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { titleCase, useMediaMobile } from "../../utils/utils";
import { VoiceSection } from "./VoiceSection";

export interface AIAgentSectionProps {
  disabled: boolean
}
export function AIAgentSection(props: AIAgentSectionProps) {
  const {disabled} = props
  const isMobile = useMediaMobile();
  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5}>
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
          Template
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <Select
          value="receptionist"
          sx={{
            maxWidth: 530,
            width: "100%",
          }}
          disabled={disabled}
        >
          <MenuItem key="receptionist" value="receptionist">
            {titleCase("receptionist")}
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{marginBottom: isMobile ? 0 : 1 }}>
          Voice
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <VoiceSection disabled={disabled}/>
      </Grid>
    </Grid>
  );
}
